<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency, formatNumber} from '@/utils/formatter'
import { ref, onMounted } from '@vue/composition-api'
import router from "@/router"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";

const DETAIL_ORDER = {
  oddGoodstypecode: 'GDTGNC', 
  oddGoodsTypeDescr: 'General Cargo',
  oddPackingtypecode: 'PACKRG', 
  oddPackingstypedesc: 'Karung',
  oddCollies: '', 
  oddWeightPerColly: '',
  oddWidth: '', 
  oddHeight: '', 
  oddLength: '', 
  oddVolumePerColly: 0,
  oddChargeweightactual: 0,
  oddChargeweightround: 0,
  oddFreightcharge:0,
  oddSurchargepct:0,
  oddSurchargeamount:0,
  oddHandlingcharge:0,
  oddTotalcharge:0,
  oddRemarks: '', 
  //oddChargearr:[]
  }

const SCHEDULE_CLEAR = {
      origin_port:'',
      destination_port:'',
      sch_vendor:'',
      sch_aircrafttype:'',
      sch_flight_no:'',
      sch_number_of_transit:'',
      sch_duration:'',
      sch_tariff_per_kg:'',
      departure_datetime: new Date().getTime(),
      customer:'',
      arrival_datetime: new Date().getTime()
}

  const ORDER_CLEAR = {
      ordCollies: 0,
      ordWeightactual: 0,
      ordWeightcharge: 0,
      ordWeight: 0,
      ordFreightcharge:0,
      ordSurcharge:0,
      ordHandlingcharge:0,
      ordHandlingdgcharge:0,
      ordDiscountpct:0,
      ordDiscountamount:0,
      ordVatcharge:0,
      ordGtotalcharge:0,
      ordShipperName: '',
      ordShipperAddress: '',
      ordShipperPhone: '',
      ordConsigneeName: '',
      ordConsigneeAddress: '',
      ordConsigneePhone: '',
      details: [],
      ordercharges: []
  }

export default {
  page: {
    title: "Create Project Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    Layout,
    PageHeader,
  },
  props:{
      schedule_id: String
  },
  data() {
    return {
      title: "Create Booking Order",
      items: [],
      select_label: "Select"
    };
  },
  
  setup(props){
    const scheduleRef = ref(null)
    const detailRef = ref(null)
    const masterRef = ref(null)
    const refoddCollies = ref(null)
    const refoddWeightPerColly = ref(null)
    const { $get } = useHttp()
    const inputedSchedule = ref({
     ...SCHEDULE_CLEAR
    })
    const configDropship = ref({})
    const orderRules = ref({})
    const maxcargodimentioncm = ref({})
    const LOV = ref({
      goodsType: [],
      addressBook:[],
      packingType:[],
      aircraftType:[],
      ports:[],
      vendors:[],
      customers:[],
    })
    let pScheduleId = ''
    if(typeof props.schedule_id !== 'undefined'){
      pScheduleId = props.schedule_id
    }
    const form = ref({
      ...ORDER_CLEAR,
      ordScheduleId: pScheduleId,
      ordAdminfee:0
      }
    )

    // const setForm = () => {
    //   const tempform = JSON.parse(localStorage.getItem('order'))
    //   if (tempform !== null){
    //     if(form.value.ordScheduleId === tempform.ordScheduleId || typeof props.schedule_id === 'undefined'){
    //       form.value = tempform
    //     }
    //   }
    // }

    const currentDetailOrder = ref({...DETAIL_ORDER})

    const sumChargeableWeight = () =>{
      form.value.ordWeightactual = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddWeightPerColly*curr.oddCollies), 0)
      form.value.ordWeightcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddChargeweightactual*curr.oddCollies), 0)
      form.value.ordWeight = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddChargeweightround*curr.oddCollies), 0)
      form.value.ordCollies = form.value.details.reduce((accumulator, {oddCollies}) => (accumulator + parseInt(oddCollies)), 0)
      // form.value.ordercharges = []
      // let ordercharge = {
      //   ochChargetypecode: 'OCHMMA', 
      //   ochChargetypename: 'Admin', 
      //   ochChargepct: 0, 
      //   ochChargeamount: 15000
      // }
      // form.value.ordercharges.push({...ordercharge})
      form.value.ordFreightcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddFreightcharge*curr.oddCollies), 0)
      //form.value.ordFreightcharge = form.value.ordWeight * inputedSchedule.value.price_kg
      // ordercharge = {
      //   ochChargetypecode: 'OCHMMA', 
      //   ochChargetypename: 'Freight', 
      //   ochChargepct: 0,
      //   ochChargeamount: form.value.ordFreightcharge
      // }
      // form.value.ordercharges.push({...ordercharge})
      
      form.value.ordHandlingcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddHandlingcharge*curr.oddCollies), 0)
      form.value.ordSurcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddSurchargeamount*curr.oddCollies), 0)
      let gtotalbeforvat = parseInt(form.value.ordFreightcharge) + parseInt(form.value.ordSurcharge) - parseInt(form.value.ordDiscountamount) + parseInt(form.value.ordAdminfee)
      form.value.ordVatcharge = gtotalbeforvat * 0.01
      form.value.ordGtotalcharge = gtotalbeforvat + form.value.ordVatcharge
    }

    // const getSchedule = async () => {
    //   setForm()
    //   const {data} = await $get({ url: 'master/schedule_available/' + form.value.ordScheduleId})
    //   inputedSchedule.value = data
    //   getVendorconfig()
    // }
    
    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

    const getVendors = async () => {
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendors = data
    }

    const getCustomers = async () => {
      const {data} = await $get({ url: 'master/customers'})
      LOV.value.customers = data
    }
    
    const getVendorconfig = async () => {
      const validated = await scheduleRef.value.validate()
      if(validated){
      console.log(inputedSchedule.value)
      const {data} = await $get({ url: 'master/vendorconfigall/'+inputedSchedule.value.sch_vendor.id+'/'+inputedSchedule.value.origin_port.prt_id+'/'+inputedSchedule.value.sch_aircrafttype.lbc_code})
      if (data!==null){
        orderRules.value = data.orderrule.vnc_configs.goods_validation
        configDropship.value = data.dropship.vnc_configs.dropship
        maxcargodimentioncm.value  = data.orderrule.aircrafttype.value.maxcargodimentioncm
        console.log(maxcargodimentioncm.value)

      form.value.ordAdminfee = orderRules.value.adminfee
      }else{
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "The dimensions are over capacity",
          html: `The maximum length: ${maxcargodimentioncm.value.length}, width: ${maxcargodimentioncm.value.height}, height: ${maxcargodimentioncm.value.height}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
      }

      scheduleRef.value.reset();
      
      }

    }

    const getAircraftType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/ACT'})
      LOV.value.aircraftType = data
    }

    const getPackingType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/PAC'})
      LOV.value.packingType = data
    }

    const getAddressBook = async () => { 
      const {data} = await $get({ url: 'master/address_books'})
      LOV.value.addressBook = data
    }

    const getGoodsType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/GDT'})
      LOV.value.goodsType = data
    }

    const goPreview = async ()=> {
      if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){
        //i18n.createorder = form.value;
        inputedSchedule.value.sch_availablecapacity = form.value.ordWeight;
        localStorage.setItem('order', JSON.stringify(form.value));
        localStorage.setItem('scheduleOrder', JSON.stringify(inputedSchedule.value));
        masterRef.value.reset();
        router.push({ name: 'apps-order-project-createpreview', })
        masterRef.value.reset();
      } 
        }
    }

    const resetForm = ()=> {
      form.value = ORDER_CLEAR
      inputedSchedule.value = SCHEDULE_CLEAR
      localStorage.removeItem('order');
      localStorage.removeItem('orderSchedule');
    }

    const validationPercolly = ()=> {
      const vordWeight = isNaN(parseInt(form.ordWeight)) ? 0 : parseInt(form.ordWeight)
      console.log( parseInt(inputedSchedule.value.sch_availablecapacity) )
      console.log( vordWeight + (parseInt(currentDetailOrder.value.oddChargeweightround) * parseInt(currentDetailOrder.value.oddCollies)) )
      console.log(  (parseInt(currentDetailOrder.value.oddChargeweightround) * parseInt(currentDetailOrder.value.oddCollies)) )
      if(
        parseFloat(currentDetailOrder.value.oddLength) > maxcargodimentioncm.value.length ||
        parseFloat(currentDetailOrder.value.oddWidth) > maxcargodimentioncm.value.width ||
        parseFloat(currentDetailOrder.value.oddHeight) > maxcargodimentioncm.value.height
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "The dimensions are over capacity",
          html: `The maximum length: ${maxcargodimentioncm.value.length}, width: ${maxcargodimentioncm.value.height}, height: ${maxcargodimentioncm.value.height}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else if(
         parseFloat(orderRules.value.colly_actualweight_max) < parseFloat(currentDetailOrder.value.oddWeightPerColly)
        ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The Maximum of Actual/Gross Weight per colly is  ${orderRules.value.colly_actualweight_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        currentDetailOrder.value.oddWeightPerColly = ''
        refoddWeightPerColly.value.focus()
        return false
      }else if(parseInt(orderRules.value.total_collies_max) < parseInt(currentDetailOrder.value.oddCollies)){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The maximum number of collies are ${orderRules.value.total_collies_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        currentDetailOrder.value.oddCollies = ''
        refoddCollies.value.focus()
        return false
      }else if(
        parseInt(inputedSchedule.value.sch_availablecapacity) < 
          (vordWeight + (parseInt(currentDetailOrder.value.oddChargeweightround) * parseInt(currentDetailOrder.value.oddCollies)))
        ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The maximum total weight per order are ${inputedSchedule.value.sch_availablecapacity}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else if(
        parseInt(orderRules.value.total_actualweight_max) < 
          (vordWeight + (currentDetailOrder.value.oddChargeweightround * parseInt(currentDetailOrder.value.oddCollies))) 
        ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The maximum total weight per order are ${orderRules.value.total_actualweight_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    const validationDetail = ()=> {
     if(
       form.value.details.length<1 ||
       form.value.details === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    const calculateWeight = ()=> {
      currentDetailOrder.value.oddVolumePerColly = (currentDetailOrder.value.oddLength * currentDetailOrder.value.oddWidth * currentDetailOrder.value.oddHeight)/6000
      if(currentDetailOrder.value.oddVolumePerColly  > currentDetailOrder.value.oddWeightPerColly){
        currentDetailOrder.value.oddChargeweightactual = currentDetailOrder.value.oddVolumePerColly
      }else{
        currentDetailOrder.value.oddChargeweightactual = currentDetailOrder.value.oddWeightPerColly
      }
      if(currentDetailOrder.value.oddChargeweightactual < orderRules.value.colly_chargeableweight_min){
        currentDetailOrder.value.oddChargeweightactual = orderRules.value.colly_chargeableweight_min
      }
      currentDetailOrder.value.oddChargeweightround = Math.ceil(currentDetailOrder.value.oddChargeweightactual)
      validationPercolly()
    }

    const selectConsignee = (selectedOption)=> {
      form.value.ordConsigneeName = selectedOption.consignee_name
      form.value.ordConsigneeAddress = selectedOption.consignee_address
      form.value.ordConsigneePhone = selectedOption.consignee_phone
    }

    // Add row details
    const addRow = async () => {
      const validated = await detailRef.value.validate()
      if (validated) {
        if(validationPercolly()){
        // let detailcharge = {
        //   odcChargetypecode: 'OCHMMA', 
        //   odchChargetypename: 'Admin1', 
        //   odcChargepct: 0, 
        //   odcChargeamountperkg: 0,
        //   odcChargeamountpercolly: 0,
        //   odcChargeamountperttl: 15000
        // }
        //currentDetailOrder.value.oddChargearr.push({...detailcharge})
          calculateWeight()
          currentDetailOrder.value.oddFreightcharge = currentDetailOrder.value.oddChargeweightround * inputedSchedule.value.sch_tariff_per_kg
          const data_surcharge_goodstype = orderRules.value.surcharge_goodstype.filter(
            data => data["goodstype"] === selectedGoodsType.value.lbc_code
          )
          if(data_surcharge_goodstype.length > 0 ){
            currentDetailOrder.value.oddSurchargepct = data_surcharge_goodstype[0].extchargingpercent
          }
          const data_surcharge_heavy = orderRules.value.surcharge_heavy.filter(
            data => parseInt(data["minkg"]) <= Math.ceil(currentDetailOrder.value.oddWeightPerColly) && 
                    parseInt(data["maxkg"]) >= Math.ceil(currentDetailOrder.value.oddWeightPerColly)
          )
          if(data_surcharge_heavy.length > 0){
            if(data_surcharge_heavy[0].extchargingpercent > currentDetailOrder.value.oddSurchargepct){
              currentDetailOrder.value.oddSurchargepct = data_surcharge_heavy[0].extchargingpercent
            }
          }
          currentDetailOrder.value.oddSurchargeamount = currentDetailOrder.value.oddFreightcharge * currentDetailOrder.value.oddSurchargepct / 100
          // currentDetailOrder.value.oddHandlingcharge = 
          currentDetailOrder.value.oddTotalcharge = ((currentDetailOrder.value.oddFreightcharge + currentDetailOrder.value.oddSurchargeamount + currentDetailOrder.value.oddHandlingcharge) * currentDetailOrder.value.oddCollies)
          
          form.value.details.push({
            ...currentDetailOrder.value,
            oddGoodstypecode: selectedGoodsType.value.lbc_code,
            oddGoodsTypeDescr : selectedGoodsType.value.descr_en,
            oddPackingtypecode: selectedPackingType.value.lbc_code, 
            oddPackingstypedesc: selectedPackingType.value.descr_en,
          })
          currentDetailOrder.value = {...DETAIL_ORDER}
          detailsLength.value = form.value.details.length +1
          selectedGoodsType.value = {}
          selectedPackingType.value = {}
          sumChargeableWeight()
        }
        detailRef.value.reset();
      }//else{}
    }
    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 0) return false
      form.value.details.splice(index, 1)
      detailsLength.value = form.value.details.length +1
      sumChargeableWeight()
    }

    const detailsLength = ref(form.value.details.length+1)
    const selectedGoodsType = ref(null)
    const selectedPackingType = ref(null)
    const selectedAddressBook = ref({consignee_name:'Please', consignee_address:'Click', consignee_phone:'to Select'})

    onMounted(() => {
     getGoodsType()
     getPackingType()
     getAddressBook()
     getAircraftType()
     getPorts()
     getVendors()
     getCustomers()
    })

 
 return{
  refoddCollies,
  refoddWeightPerColly,
   required,
   scheduleRef,
   detailRef,
   masterRef,
   inputedSchedule,
   orderRules, configDropship, maxcargodimentioncm,
   LOV,
   form,
   resetForm,
   currentDetailOrder,
   calculateWeight,
   addRow,
   removeRow,
   getVendorconfig,
   detailsLength,
   sumChargeableWeight,
   selectedGoodsType,
   selectedPackingType,
   selectedAddressBook,
   selectConsignee,
  formatCurrency,
  formatNumber,
  goPreview
 }
  },
  methods: {
    customLabelConsignee ({ consignee_name, consignee_address, consignee_phone }) {
      return `${consignee_name} – ${consignee_address} – ${consignee_phone}`
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="scheduleRef">
                <b-form @submit.prevent>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <label>Customer</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="customer"
                        rules="required"
                        >
                        <multiselect v-model="inputedSchedule.customer" :options="LOV.customers" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Vendor</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="vendor"
                        rules="required"
                        >
                        <multiselect v-model="inputedSchedule.sch_vendor" :options="LOV.vendors" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Flight No</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="flightNo"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="inputedSchedule.sch_flight_no"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Departure Time</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="departureTime"
                        rules="required"
                        >
                        <b-form-input id="departure_datetime" type="datetime-local" v-model="inputedSchedule.departure_datetime" format='dd-MM-yyyy HH:mm'  valueType="format"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Arrival Time</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="arrivalTime"
                        rules="required"
                        >
                        <b-form-input id="arrival_datetime" type="datetime-local" v-model="inputedSchedule.arrival_datetime" format='dd-MM-yyyy HH:mm'  valueType="format"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Duration</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="duration"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="inputedSchedule.sch_duration"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <label>Route From</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="routeFrom"
                        rules="required"
                        >
                        <multiselect v-model="inputedSchedule.origin_port" :options="LOV.ports" label="prt_name" track-by="prt_name" :select-label="select_label"></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Route To</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="routeTo"
                        rules="required"
                        >
                        <multiselect v-model="inputedSchedule.destination_port" :options="LOV.ports" label="prt_name" track-by="prt_name" :select-label="select_label"></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Aircraft Type</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="aircraftType"
                        rules="required"
                        >
                        <multiselect v-model="inputedSchedule.sch_aircrafttype" :options="LOV.aircraftType" label="descr_id" track-by="id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Number of Transit</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="numberOfTransit"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="inputedSchedule.sch_number_of_transit"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Tariff PerKg</label><br />
                         <validation-provider
                        #default="{ errors }"
                        name="tariffPerKg"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="inputedSchedule.sch_tariff_per_kg"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <hr/>
                          <b-button variant="primary" @click="getVendorconfig()">
                            <i class="ri-edit-2-fill"></i>
                            Set
                          </b-button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
              <validation-observer ref="detailRef">
                <b-form @submit.prevent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label>Goods Type <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="goodsType"
                        rules="required"
                      >
                        <multiselect v-model="selectedGoodsType" :options="LOV.goodsType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Actual Weight Per Colly <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="actualWeight"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" ref="refoddWeightPerColly" v-model="currentDetailOrder.oddWeightPerColly"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="row">
                      <div class="col-md-4 form-group">
                        <label>Length (CM) <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="length"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddLength"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      </div>
                      <div class="col-md-4 form-group">
                        <label>Width (CM) <span class="text-danger">*</span></label><br />
                          <validation-provider
                            #default="{ errors }"
                            name="width"
                            rules="required"
                          >
                            <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddWidth"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </div>
                      <div class="col-md-4 form-group">
                        <label>Height (CM) <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="height"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddHeight"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                        
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col-md-6 form-group">
                        <label>Volume Per Colly</label><br />
                        <b-form-input readonly v-model="currentDetailOrder.oddVolumePerColly"></b-form-input>
                      </div>
                      <div class="col-md-6 form-group">
                        <label>Chargeable Weight Per Colly </label><br />
                        <b-form-input readonly v-model="currentDetailOrder.oddChargeweightround"></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label>Collies<span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="collies"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" ref="refoddCollies" v-model="currentDetailOrder.oddCollies"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Packing <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="goodsPacking"
                        rules="required"
                      >
                        <multiselect v-model="selectedPackingType" :options="LOV.packingType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Remarks</label><br />
                      <b-form-input v-model="currentDetailOrder.oddRemarks"></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <hr/>
                    <b-button variant="primary" @click="addRow()">
                      <i class="ri-add-fill"></i>
                      Add
                    </b-button>
                  </div>
                  </div>
                </b-form>
              </validation-observer>
              <div class="row">
              <div class="col-12">
                <hr/>
                  <div class="table-responsive  mb-2 mt-2">
                    <table class="table font-size-13 table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Goods Type</th>
                          <th>Collies</th>
                          <th>Actual Weight</th>
                          <th>Volume</th>
                          <th>Chargeable Weight</th>
                          <th>Packing </th>
                          <th>Remarks</th>
                          <th>Additional Charge</th>
                          <th>Total Charge</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <th scope="row">{{index+1}}</th>
                          <td>{{detailOrder.oddGoodsTypeDescr}}</td>
                          <td class="text-right">{{detailOrder.oddCollies}}</td>
                          <td class="text-right">{{detailOrder.oddWeightPerColly}}</td>
                          <td class="text-right">{{detailOrder.oddVolumePerColly}}</td>
                          <td class="text-right">{{detailOrder.oddChargeweightround}}</td>
                          <td>{{detailOrder.oddPackingstypedesc}}</td>
                          <td>{{detailOrder.oddRemarks}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddSurchargeamount+detailOrder.oddHandlingcharge)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddTotalcharge)}}</td>
                          <td>
                            <b-button size="sm" variant="danger" @click="removeRow(index)">
                              <i class="ri-delete-bin-line"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="masterRef">
                <b-form @submit.prevent>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <label>Shipper Name <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shippername"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperName"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                         </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Shipper Address <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shipperaddress"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperAddress"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Shipper Phone <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shipperphone"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperPhone"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-3 hidden">
                        <label>Find Consignee</label><br />
                        <multiselect :select-label="select_label"  placeholder="Click to Select" v-model="selectedAddressBook" :options="LOV.addressBook" :custom-label="customLabelConsignee" @select="selectConsignee"></multiselect>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Name <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneename"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneeName"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Address <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneeaddress"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneeAddress"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Phone <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneephone"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneePhone"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body mb">
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Chargeable Weight"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{form.ordWeight}}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Freight Charges"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordFreightcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Surcharge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordSurcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Handling Heavy Charge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingcharge) }}</h6></div>
                </b-form-group>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Handling DG Charge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingdgcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Admin SMU"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordAdminfee) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Admin Fee"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordVatcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Grand Total"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordGtotalcharge) }}</h6></div>
                </b-form-group>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click="goPreview">
                    <i class="ri-arrow-right-s-fill align-middle"></i>
                    Next
                  </b-button>
                  <b-button variant="danger" @click="resetForm">
                    <i class="mdi mdi-refresh align-middle"></i> Reset
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>